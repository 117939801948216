import React from "react";
import { connect } from "react-redux";
import { withLocalize, Translate } from "react-localize-redux";
import $ from "jquery";

import { BasePureComponent } from "common/components/Base";
import LanguageSelector from "app/components/Footer/components/LanguageSelector";
import Modal from "common/components/Modal";
import PrivacyPolicy from "app/scenes/PrivacyPolicy";
import TermsOfUse from "app/scenes/TermsOfUse";
import { currentDate } from "common/util";
import i18n from "./i18n.json";
import "./styles.scss";

/* The application's footer. */
class Footer extends BasePureComponent {
  constructor(props) {
    // parent
    super(props);

    // load translations
    props.addTranslation(i18n);

    // for modal management
    this.state = {
      ...this.state,
      showPrivacy: false,
      showTermsOfUse: false,
    };
  }

  componentDidUpdate(prevProps, prevState) {
    // parent
    super.componentDidUpdate(prevProps, prevState);

    // privacy modal?
    if (this.state.showPrivacy) {
      // show it
      $("#fsp-privacy").modal();

      // clear the state when it's closed
      const ssThis = this;
      $("#fsp-privacy").on("hidden.bs.modal", function (e) {
        // clear the state
        ssThis.setState({ showPrivacy: false });
      });
    }

    // terms of use modal?
    else if (this.state.showTermsOfUse) {
      // show it
      $("#fsp-terms").modal();

      // clear the state when it's closed
      const ssThis = this;
      $("#fsp-terms").on("hidden.bs.modal", function (e) {
        // clear the state
        ssThis.setState({ showTermsOfUse: false });
      });
    }
  }

  render() {
    // parent
    super.render();

    // render
    return (
      <>
        {/* modals */}
        {this.state.showPrivacy ? (
          <Modal
            id="fsp-privacy"
            header={this.props.translate("footer.privacy.label")}
            showOverlay={false}
          >
            <div className="container-fluid pt-3 pb-3">
              <PrivacyPolicy />
            </div>
          </Modal>
        ) : (
          this.state.showTermsOfUse && (
            <Modal
              id="fsp-terms"
              header={this.props.translate("footer.termsOfUse.label")}
              showOverlay={false}
            >
              <div className="container-fluid pt-3 pb-3">
                <TermsOfUse />
              </div>
            </Modal>
          )
        )}

        <div className="container-fluid fsp-footer fsp-background d-print-none">
          <div className="no-gutters row fsp-footer-container">
            <div className="col text-left fsp-footer-links">
              <LanguageSelector />
            </div>
            <div className="col text-right fsp-footer-links">
              <span className="d-none d-sm-block">
                Freedom&nbsp;School Partners&nbsp;&copy;&nbsp;
                {currentDate().year()}
              </span>
              <span
                className="fsp-link"
                title={this.props.translate("footer.privacy.title")}
                onClick={() => {
                  // show the modal
                  this.setState({ showPrivacy: true });
                }}
              >
                <Translate id="footer.privacy.label" />
              </span>{" "}
              |{" "}
              <span
                className="fsp-link"
                title={this.props.translate("footer.termsOfUse.title")}
                onClick={() => {
                  // show the modal
                  this.setState({ showTermsOfUse: true });
                }}
              >
                <Translate id="footer.termsOfUse.label" />
              </span>
            </div>
          </div>
        </div>
      </>
    );
  }
}

// turn this into a container component
Footer = withLocalize(connect()(Footer));

// set prop types and required-ness
Footer.propTypes = {};

// set default props
Footer.defaultProps = {};

export default Footer;
