import { combineReducers } from "redux";
import { reducer as reduxFormReducer } from "redux-form";
import { localizeReducer } from "react-localize-redux";
import { connectRouter } from "connected-react-router";

import { context } from "common/entities/Context/reducer";
import { alerts } from "./components/Alerts/reducer";
import { menu } from "./components/Menu/reducer";
import { busy } from "./components/Busy/reducer";
import { navigate } from "common/components/Navigate/reducer";
import { breadcrumbs } from "common/components/Breadcrumbs/reducer";

// create a root reducer
const rootReducer = (history) => {
  // combine all application reducers
  const appReducers = combineReducers({
    context: context,
    alerts: alerts,
    busy: busy,
    menu: menu,
    navigate: navigate,
    breadcrumbs: breadcrumbs,
    form: reduxFormReducer.plugin({}),
    localize: localizeReducer,
    router: connectRouter(history),
  });

  // wrap them in a root reducer
  return (state, action) => {
    // run standard reducers
    const newState = appReducers(state, action);

    // enable for logging
    if (false) {
      console.debug(
        "Ran action",
        action,
        "against state",
        state,
        "and got new state",
        newState
      );
    }

    // return the new state
    return newState;
  };
};

export default rootReducer;
