/**
 * Fetches a piece of content by name.
 */
export function fetchSnippetByName(name, locale = "en") {
  // using thunk middleware, so we return a function
  return (dispatch) => {
    // make the call
    return dispatch({
      type: "FETCH_SNIPPET",
      payload: {
        request: {
          method: "get",
          url: `/content/${name}${locale ? `?locale=${locale}` : ""}`,
        },
      },
    });
  };
}
