import { BasePureComponent } from "common/components/Base";
import * as errors from "common/util/errors";

/**
 * A base page pure component from which all other pages should extend.
 *
 * Note that React discourages inheritance. We do it because I couldn't
 * figure out a better way to handle behavior that cuts across every
 * page of the app without sprinkling boilerplate code everywhere.
 *
 * Let the record show that I'm not happy with this solution and am in
 * favor of replacing it with a cleaner solution should one be found.
 */
export default class BasePage extends BasePureComponent {
  constructor(props) {
    super(props);

    // for data load management; it's fine if components don't need this
    this.state = {
      ...this.state,
      dataLoadError: false,
      dataLoading: true,
    };

    // to make sure we don't update state after unmounting
    this._cancel = false;
  }

  componentWillUnmount() {
    // parent
    super.componentWillUnmount();

    // flag this so that we don't try to update the state
    this._cancel = true;
  }

  // collates 0...N promises to load data, returning once they have all completed
  loadData(
    loaders,
    getUpdatedState = null,
    errorHandler = null,
    trackProgress = true
  ) {
    // cancel?
    if (this._cancel) {
      return;
    }

    // make sure we have an array
    if (!Array.isArray(loaders)) {
      loaders = [loaders];
    }

    // shortcut
    if (loaders.length === 0) {
      this.setState({ dataLoading: false });
      return;
    }

    // loading...
    if (trackProgress) {
      this.setState({ dataLoadError: false, dataLoading: true });
    }

    // run all loaders, passing through the results but handling errors
    return Promise.all(loaders)
      .then((results) => {
        // cancel?
        if (this._cancel) {
          return;
        }

        // get the state updates
        let state = {};
        if (results && getUpdatedState) {
          state = getUpdatedState(results);
        }

        // update the state and note that we are no longer loading
        if (!this._cancel) {
          this.setState({ ...state, dataLoading: false });
        }
      })
      .catch((e) => {
        // special case
        if (
          e &&
          e.code &&
          (e.code === errors.NOT_AUTHENTICATED ||
            e.code === errors.NOT_AUTHORIZED)
        ) {
          // update the state to indicate that we're done trying
          if (trackProgress) {
            this.setState(() => ({
              dataLoading: false,
            }));
          }

          // abort
          return;
        }

        // note it
        if (e) {
          console.error("Error loading data", e);
        } else {
          console.error("Unknown error loading data", e);
        }

        // cancel?
        if (this._cancel) {
          return;
        }

        // note that we are no longer loading
        if (trackProgress) {
          this.setState({ dataLoadError: true, dataLoading: false });
        }

        // if we have an error handler, invoke it
        if (errorHandler) {
          const state = errorHandler(e);
          if (state) {
            this.setState({ ...state });
          }
        }
      });
  }

  // was there a load error?
  dataLoadError(state = null) {
    // if a state is provided, use it
    state = state ? state : this.state;

    // we don't care if anything is still loading
    return state.dataLoadError;
  }

  // did the data load finish succesfully?
  dataLoadSuccess(state = null) {
    // if a state is provided, use it
    state = state ? state : this.state;

    // we consider it successful if we're not loading and we have no error
    return !state.dataLoading && !state.dataLoadError;
  }

  // still loading?
  dataLoading(state = null) {
    // if a state is provided, use it
    state = state ? state : this.state;

    // we consider loading complete if there is an error
    return state.dataLoading && !state.dataLoadError;
  }
}
