import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withLocalize, Translate } from "react-localize-redux";
import $ from "jquery";

import { BasePureComponent } from "common/components/Base";
import i18n from "./i18n.json";
import "./styles.scss";

// select language
class LanguageSelector extends BasePureComponent {
  constructor(props) {
    // parent
    super(props);

    // load translations
    props.addTranslation(i18n);

    // a hint can be rendered but hidden
    this.state = {
      ...this.state,
      renderHint: true,
      showHint: false,
    };

    // for tracking component sizing
    this.state = {
      ...this.state,
      windowSize: { width: window.innerWidth, height: window.innerHeight },
      footerHeight: $(".fsp-footer-container")
        ? $(".fsp-footer-container").height()
        : null,
    };
  }

  componentDidMount() {
    // parent
    super.componentDidMount();

    // show the language hint after a small delay
    setTimeout(
      () =>
        this.setState({
          showHint: true,
          footerHeight: $(".fsp-footer-container")
            ? $(".fsp-footer-container").height()
            : null,
        }),
      1000
    );

    // capture resize events...
    window.addEventListener("resize", () => {
      // ... but only if showing the hint
      if (this.state.showHint) {
        // we don't use the window size, but we need to capture it to force a re-render
        this.setState({
          windowSize: { width: window.innerWidth, height: window.innerHeight },
          footerHeight: $(".fsp-footer-container")
            ? $(".fsp-footer-container").height()
            : null,
        });
      }
    });
  }

  componentDidUpdate(prevProps, prevState) {
    // parent
    super.componentDidUpdate(prevProps, prevState);

    // left-hand x-coordinate of second option
    const secondOptionLeft =
      $(".fsp-language-option-link") &&
      $(".fsp-language-option-link").eq(1) &&
      $(".fsp-language-option-link").eq(1).offset()
        ? $(".fsp-language-option-link").eq(1).offset()["left"]
        : null;

    // right-hand x-coordinate of first option
    const firstOptionRight =
      $(".fsp-language-option-link") &&
      $(".fsp-language-option-link").first() &&
      $(".fsp-language-option-link").first().offset()
        ? $(".fsp-language-option-link").first().offset()["left"] +
          $(".fsp-language-option-link").first().outerWidth()
        : null;

    // put the arrow in the correct spot; the extra 10 is the arrow width
    if (secondOptionLeft && firstOptionRight) {
      $("head").append(
        `<style>.fsp-language-hint:after{left:${
          (firstOptionRight + secondOptionLeft) / 2 - 30
        }px !important;}</style>`
      );
    }
  }

  render() {
    // parent, for lifecycle logging
    super.render();

    // render a hint about language choices
    const renderHint = () => {
      // time it out after 8 seconds
      if (this.state.footerHeight) {
        setTimeout(() => this.setState({ showHint: false }), 8000);
      }

      // flag it as seen shortly after
      if (this.state.footerHeight) {
        setTimeout(
          () => window.$localStorage.setItem("languageHint", true),
          9000
        );
      }

      // the hint
      return (
        <div
          className={
            "fsp-clickable fsp-language-hint" +
            (!this.state.showHint || !this.state.footerHeight ? " hidden" : "")
          }
          style={
            this.state.footerHeight
              ? { bottom: this.state.footerHeight + 12 }
              : null
          }
          onClick={() => {
            this.setState({ renderHint: false });
          }}
        >
          <Translate id="languageSelector.hint" />
        </div>
      );
    };

    // render language choices
    const renderLanguageChoices = () => {
      const languages = [];
      if (this.props.languages && this.props.languages.length > 0) {
        this.props.languages.forEach((language) => {
          const selected = language.code === this.props.language;
          languages.push(
            <div
              key={language.code}
              className="fsp-language-option align-middle"
            >
              <span
                className={
                  "fsp-language-option-link " +
                  (selected ? "fsp-language-selected" : "fsp-clickable")
                }
                onClick={
                  selected
                    ? null
                    : () => {
                        // hide the hint
                        this.setState({ renderHint: false });

                        // store the selection
                        window.$localStorage.setItem("language", language.code);

                        // change the active language
                        this.props.setActiveLanguage(language.code);
                      }
                }
              >
                <Translate id={`languageSelector.language.${language.code}`} />
              </span>
            </div>
          );
        });
      }
      return <div className="fsp-language-options">{languages}</div>;
    };

    // render
    return (
      <>
        <>{!this.props.hintShown && this.state.renderHint && renderHint()}</>
        {renderLanguageChoices()}
      </>
    );
  }
}

// map state to properties relevant to this component
const mapStateToProps = () => ({
  // current language
  language: window.$localStorage.getItem("language"),

  // have we seen the hint before?
  hintShown: window.$localStorage.getItem("languageHint"),
});

// turn this into a container component
LanguageSelector = withLocalize(connect(mapStateToProps)(LanguageSelector));

// set prop types and required-ness
LanguageSelector.propTypes = { languages: PropTypes.array };

export default LanguageSelector;
