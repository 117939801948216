import $ from "jquery";
import { pick as dotPick } from "dot-object";
import moment from "moment/moment";

import * as v from "app/variables";

// scrolls to the top
export function scrollToTop() {
  window.scrollTo(0, 0);
}

// scrolls to an anchor
export function scrollToAnchor(anchor, modalId = null) {
  // safety check
  if ($(`#${anchor}`)) {
    $(document).ready(function () {
      // we need to account for the header
      let offset = 0;
      let header = $("#fsp-header");
      if (header) {
        offset = header.height();
      }

      // scroll
      if ($(`#${anchor}`).offset()) {
        $(modalId ? `#${modalId}` : "html, body")
          .animate(
            {
              // account for the header
              scrollTop: modalId
                ? $(`#${modalId}`).offset().top - offset
                : $(`#${anchor}`).offset().top - offset,
            },
            0
          )
          .promise()
          .then(() => {
            // TODO: the scrolling may have shrunk the header, but
            //       we can't accurately capture the new header until the
            //       on('scroll') logic completes; need to figure that out
            if (!modalId) {
              header = $("#fsp-header");
              if (header.height() !== offset) {
                // new header offset
                offset = header.height();

                // scroll again
                $("html, body").animate(
                  {
                    // account for the header
                    scrollTop: $(`#${anchor}`).offset().top - offset,
                  },
                  0
                );
              }
            }
          });
      }
    });
  }
}

// clones an object
export function clone(o) {
  if (o) {
    return JSON.parse(JSON.stringify(o));
  } else {
    return o;
  }
}

// Google map styles
export function getMapStyles() {
  return [
    {
      featureType: "administrative.land_parcel",
      stylers: [
        {
          visibility: "off",
        },
      ],
    },
    {
      featureType: "administrative.neighborhood",
      stylers: [
        {
          visibility: "off",
        },
      ],
    },
    {
      featureType: "poi",
      elementType: "labels.text",
      stylers: [
        {
          visibility: "off",
        },
      ],
    },
    {
      featureType: "poi.business",
      stylers: [
        {
          visibility: "off",
        },
      ],
    },
    {
      featureType: "poi.park",
      elementType: "labels.text",
      stylers: [
        {
          visibility: "off",
        },
      ],
    },
    {
      featureType: "road",
      elementType: "labels",
      stylers: [
        {
          visibility: "off",
        },
      ],
    },
    {
      featureType: "water",
      elementType: "labels.text",
      stylers: [
        {
          visibility: "off",
        },
      ],
    },
  ];
}

// see if two VALID addresses match; addresses missing components are ignored
export function addressesMatch(a, b, compareStreet2 = true) {
  // easy case
  if ((!a && !b) || (a && b && a === b)) {
    return true;
  }

  // another easy case
  if ((a && !b) || (!a && b)) {
    return false;
  }

  // street
  if (a.street1 && b.street1) {
    // force lowercase, strip periods (but purposely nothing else),
    // and collapse multiple spaces
    const x = a.street1.toLowerCase().replace(".", "").replace(/\s\s+/g, " ");
    const y = b.street1.toLowerCase().replace(".", "").replace(/\s\s+/g, " ");

    if (x !== y) {
      return false;
    }
  }

  // street 2
  if (
    compareStreet2 &&
    ((a.street2 &&
      b.street2 &&
      a.street2.toLowerCase() !== b.street2.toLowerCase()) ||
      (a.street2 && !b.street2) ||
      (!a.street2 && b.street2))
  ) {
    return false;
  }

  // city
  if (a.city && b.city && a.city.toLowerCase() !== b.city.toLowerCase()) {
    return false;
  }

  // state
  if (a.state && b.state && a.state !== b.state) {
    return false;
  }

  // zip
  if (a.zip && b.zip) {
    if (a.zip.length === b.zip.length && a.zip !== b.zip) {
      return false;
    } else if (
      a.zip.length === 5 &&
      b.zip.length === 10 &&
      !b.zip.startsWith(a.zip + "-")
    ) {
      return false;
    } else if (
      b.zip.length === 5 &&
      a.zip.length === 10 &&
      !a.zip.startsWith(b.zip + "-")
    ) {
      return false;
    }
  }

  return true;
}

// uses Google geocoding to validate an address; returns a Promise
// that will resolve to the geocoded address, if one is found.
export function validateAddress({
  street1,
  street2,
  city,
  state,
  country,
  zip,
}) {
  return new Promise(function (resolve) {
    // get a handle to the Google API
    const google = window.google;

    // build a string address, but short-circuit if we don't have all pieces
    let address = "";

    // street address
    if (street1 && street1.length > 0) {
      address += street1;

      // city
      if (city && city.length > 0) {
        address += city + " ";

        // state
        if (state && state.length > 0) {
          address += "," + state + " ";

          // zip
          if (zip && (zip.length === 5 || zip.length === 10)) {
            // fringe case; user entered non-numeric values which will get caught by another validator
            if (
              zip.replace(/\D/g, "").length === 5 ||
              zip.replace(/\D/g, "").length === 9
            ) {
              address += zip + " ";

              // country
              address += "US ";

              // we have a full address; geocode it
              let geocoder = new google.maps.Geocoder();
              geocoder.geocode({ address: address }, (results, status) => {
                // make sure we're still alive
                if ("OK" === status && results.length > 0) {
                  // extract components from the results
                  const extractComponent = (addressComponents, type) => {
                    if (addressComponents) {
                      // iterate over all components
                      for (let c of addressComponents) {
                        // iterate over all types
                        if (c.types) {
                          for (let t of c.types) {
                            // is this the one we want?
                            if (type === t) {
                              return c.short_name;
                            }
                          }
                        }
                      }
                    }

                    // nothing found
                    return null;
                  };

                  // extract the values from the response
                  const addressComponents = results[0].address_components;
                  const geometry = results[0].geometry;
                  const streetNumber = extractComponent(
                    addressComponents,
                    "street_number"
                  );
                  const gStreet = extractComponent(addressComponents, "route");
                  const gCity = extractComponent(addressComponents, "locality");
                  const gState = extractComponent(
                    addressComponents,
                    "administrative_area_level_1"
                  );
                  const gZip = extractComponent(
                    addressComponents,
                    "postal_code"
                  );
                  const gZip4 = extractComponent(
                    addressComponents,
                    "postal_code_suffix"
                  );
                  const gLatitude = geometry.location.lat();
                  const gLongitude = geometry.location.lng();

                  // build the address
                  const o = {
                    street1:
                      streetNumber && gStreet
                        ? streetNumber + " " + gStreet
                        : streetNumber
                        ? streetNumber
                        : gStreet,
                    street2: street2 ? street2 : null, // we intentionally ignore street2 in the request and simply use what we were provided
                    city: gCity,
                    state: gState,
                    zip: gZip && gZip4 ? gZip + "-" + gZip4 : gZip,
                    lat: gLatitude,
                    lng: gLongitude,
                  };

                  // we only consider results that contain all fields that were in the request
                  if (street1 && !o.street1) {
                    resolve(null);
                  } else if (street2 && !o.street2) {
                    resolve(null);
                  } else if (city && !o.city) {
                    resolve(null);
                  } else if (state && !o.state) {
                    resolve(null);
                  } else if (zip && !o.zip) {
                    resolve(null);
                  }

                  // return
                  resolve(o);
                } else {
                  // no address found
                  resolve(null);
                }
              });
            }
          }
        }
      }
    }
  });
}

// tests if a variable is a function
export function isFunction(obj) {
  return !!(obj && obj.constructor && obj.call && obj.apply);
}

// tries to detect if a user's device is touch-enabled; this is not
// perfect, so it should be used for mission critical decisions
export const isTouchDevice = () => {
  let prefixes = " -webkit- -moz- -o- -ms- ".split(" ");
  let mq = function (query) {
    return window.matchMedia(query).matches;
  };

  if (
    !!(
      typeof window !== "undefined" &&
      ("ontouchstart" in window ||
        (window.DocumentTouch &&
          typeof document !== "undefined" &&
          document instanceof window.DocumentTouch))
    ) ||
    !!(
      typeof navigator !== "undefined" &&
      (navigator.maxTouchPoints || navigator.msMaxTouchPoints)
    )
  ) {
    return true;
  }

  // include the 'heartz' as a way to have a non matching MQ to help terminate the join
  // https://git.io/vznFH
  let query = ["(", prefixes.join("touch-enabled),("), "heartz", ")"].join("");
  return mq(query);
};

// a delay wrapped in a promise. Can be used like: delay(1000).then(...)
export function delay(t, v) {
  return new Promise(function (resolve) {
    setTimeout(resolve.bind(null, v), t);
  });
}

// checks if an object is empty
export function isEmpty(o) {
  for (let key in o) {
    if (
      o.hasOwnProperty(key) &&
      o[key] !== null &&
      !typeof o[key] !== "undefined" &&
      (typeof o[key] !== "string" || o[key] !== "")
    ) {
      return false;
    }
  }

  return true;
}

// trims all strings on an object
export function trimObject(o) {
  for (let key in o) {
    if (o.hasOwnProperty(key) && o[key] && typeof o[key] === "string") {
      o[key] = o[key].trim();
    }
  }

  return o;
}

// extracts URL params into an object from a React router "match" object;
// the key value add here is that this function handles null checks,
// leaving client code cleaner
export function extractURLParams(match) {
  if (match) {
    return match.params;
  } else {
    return {};
  }
}

// reads a file to a Base64 string
export function fileToBase64(file) {
  if (file) {
    // wrap in a promise
    return new Promise((resolve, reject) => {
      // build a reader
      const reader = new FileReader();

      // set the file
      reader.readAsDataURL(file);

      // use the promise
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  }

  // no file
  return Promise.resolve();
}

// translates underscored strings into camelCase
export function underscoreToCamelCase(s) {
  let sections = s.split("_");
  for (let i = 0; i < sections.length; i++) {
    sections[i] =
      (i !== 0 ? sections[i].charAt(0).toUpperCase() : sections[i].charAt(0)) +
      sections[i].slice(1);
  }
  return sections.join("");
}

// tests if two objects are equivalent
export function equivalent(a, b) {
  // get property names
  let aProps = Object.getOwnPropertyNames(a);
  let bProps = Object.getOwnPropertyNames(b);

  // if number of properties is different, objects are not equivalent
  if (aProps.length !== bProps.length) {
    return false;
  }

  // test each property
  for (let i = 0; i < aProps.length; i++) {
    // if values of same property are not equal, objects are not equivalent
    let propName = aProps[i];
    if (a[propName] !== b[propName]) {
      return false;
    }
  }

  // if we made it this far, objects are considered equivalent
  return true;
}

// sorts an array in place
export function sortArray(a, field = null, asc = true, caseSensitive = false) {
  if (a && Array.isArray(a)) {
    a.sort(function (a, b) {
      let x = field ? dotPick(field, a) : a;
      let y = field ? dotPick(field, b) : b;
      if (typeof x === "number") {
        return asc ? x - y : y - x;
      } else if (typeof x === "boolean") {
        if (x && y) {
          return 0;
        } else if (!x && !y) {
          return 0;
        } else if (x && !y) {
          return asc ? 1 : -1;
        } else if (!x && y) {
          return asc ? -1 : 1;
        } else {
          return 0;
        }
      } else if (typeof x === "string") {
        if (!caseSensitive) {
          x = x ? x.toLowerCase() : x;
          y = y ? y.toLowerCase() : y;
        }
        if (x && y && x.length > 0 && y.length > 0) {
          if (x < y) {
            return asc ? -1 : 1;
          } else if (x > y) {
            return asc ? 1 : -1;
          } else {
            return 0;
          }
        } else if (!x) {
          return asc ? -1 : 1;
        } else if (!y) {
          return asc ? 1 : -1;
        } else {
          return 0;
        }
      } else {
        if (x && y) {
          if (x < y) {
            return asc ? -1 : 1;
          } else if (x > y) {
            return asc ? 1 : -1;
          } else {
            return 0;
          }
        } else if (!x) {
          return asc ? -1 : 1;
        } else if (!y) {
          return asc ? 1 : -1;
        } else {
          return 0;
        }
      }
    });
  }
}

/**
 * Looks for a key in a dictionary, and if found, returns
 * the value. Otherwise, returns the key.
 */
export function getFromDictionary(key, dictionary) {
  // sanity check
  if (!key || !dictionary) {
    return key;
  }

  // see if the key is in the dictionary
  return dictionary[key] ? dictionary[key] : key;
}

/**
 * Gets the current date as a moment. Useful for mocking
 * dates for testing.
 */
export function currentDate(excludeWeekends = false) {
  // note: we never mock staging or prod
  let date = moment();
  if (
    process.env.REACT_APP_ENV === "local" ||
    process.env.REACT_APP_ENV === "qa"
  ) {
    // change this one for testing (for example, moment('2019-05-04'))
    date = moment();
  }

  // if not including weekends, move backward to last weekday
  if (excludeWeekends) {
    if (date.day() === 6) {
      date.subtract(1, "days");
    } else if (date.day() === 0) {
      date.subtract(2, "days");
    }
  }

  return date;
}

/**
 * Given an address, builds a map link.
 */
export function getMapLink(address) {
  let link = "";
  const getSegment = (s) => {
    let l = "";
    if (s) {
      if (link !== "") {
        l += ",";
      }
      l += s;
    }
    return l;
  };
  link += getSegment(address.street1);
  link += getSegment(address.city);
  link += getSegment(address.state);
  link += getSegment(address.zip);
  return "https://maps.google.com/?q=" + link;
}

/**
 * Builds a full name using first, last, and optionally, preferred names.
 */
export function fullName(person, lastFirst = false) {
  if (person) {
    return `${
      lastFirst
        ? `${person.lastName}, ${person.firstName}`
        : `${person.firstName} ${person.lastName}`
    }${person.preferredName ? ` (${person.preferredName})` : ""}`;
  } else {
    return "";
  }
}

/**
 * Given a program year, and taking into account the current date,
 * calculates for a range:
 *  - start date
 *  - end date
 *  - min date
 *  - max date
 *
 * Start and end dates will always be set. No program year will
 * result in no min/max dates.
 *
 * To figure out the start date, we follow the following rules:
 *  1. If we have a program year, and if the program year has not
 *     yet started, use the year's start date.
 *  2. If we have a program year, and if the program year is in
 *     progress, use the current date.
 *  3. If we hvae a program year, and if the program year has
 *     ended, use the year's end date.
 *  4. If we have no program year, use the current date.
 *
 * The end date will be calculated off of the start date, taking
 * into account day/week and weekend preferences. If a max date
 * is set, the end date will never be after if.
 *
 * The max date can optionally be limited to a max of the current
 * date. If max is not limited to the current date, it will be
 * set to the program year's end date, if available. If not, no
 * max will be set.
 *
 * One exception is if the min date is after today. If so, the max
 * date will be limited to the correct date after the min date
 * based on the day/week flag.
 */
export function calculateDates(
  programYear,
  byDay = false,
  constrainMax = true,
  includeWeekends = v.includeWeekends
) {
  // the state
  let dates = {};

  // the current date
  const current = currentDate(!includeWeekends);

  // figure out dates
  let startDate = moment(current);
  if (programYear) {
    const pyStartDate = moment(programYear.startDate);
    const pyEndDate = moment(programYear.endDate);
    if (startDate.isBefore(pyStartDate)) {
      // program year has not yet started
      startDate = moment(pyStartDate);
    } else if (startDate.isAfter(pyEndDate)) {
      // program year has ended
      startDate = moment(pyEndDate);
    } else {
      // program year in progress
    }

    // save min/max dates
    dates = {
      ...dates,
      minDate: pyStartDate,
      maxDate: pyEndDate,
    };
  } else {
    // no program year
  }

  // if by week, expand the date into a week
  if (!byDay) {
    startDate.startOf("week").add(includeWeekends ? 0 : 1, "day");
  } else {
    // factor in weekends
    if (!includeWeekends) {
      if (startDate.day() === 6) {
        startDate.subtract(1, "days");
      } else if (startDate.day() === 0) {
        startDate.subtract(2, "days");
      }
    }
  }

  // end date
  let endDate = moment(startDate);
  if (!byDay) {
    endDate.endOf("week").subtract(includeWeekends ? 0 : 1, "day");
  }

  // max date cannot be beyond today...
  if (dates.maxDate && dates.maxDate.isAfter(current, "day")) {
    // ... unless the min date is after today...
    if (dates.minDate && dates.minDate.isAfter(current, "day")) {
      // ... in which case we base it off of the min date, if constrained
      if (constrainMax) {
        dates.maxDate = moment(dates.minDate)
          .endOf(byDay ? "day" : "week")
          .subtract(byDay ? 0 : 1);
      }
    } else {
      // potentially limit to today
      if (constrainMax) {
        dates.maxDate = moment(current);
      }
    }
  }

  // end date cannot be beyond max date
  if (dates.maxDate && endDate.isAfter(dates.maxDate, "day")) {
    endDate = moment(dates.maxDate);
  }

  // end date can potentially not be beyond today...
  if (endDate.isAfter(current, "day")) {
    // ... unless the start date is after today...
    if (dates.startDate && dates.startDate.isBefore(current, "day")) {
      endDate = moment(current);
    }
  }

  // bundle it all up
  dates = {
    ...dates,
    startDate: startDate,
    endDate: endDate,
  };
  console.debug("Calculated dates", {
    minDate: dates.minDate ? dates.minDate.format("MM-DD-YY") : null,
    startDate: dates.startDate ? dates.startDate.format("MM-DD-YY") : null,
    endDate: dates.endDate ? dates.endDate.format("MM-DD-YY") : null,
    maxDate: dates.maxDate ? dates.maxDate.format("MM-DD-YY") : null,
  });
  return dates;
}

// strips null values from an object
export function stripNull(o) {
  if (o) {
    for (let key in o) {
      if (
        o.hasOwnProperty(key) &&
        (o[key] === null || typeof o[key] === "undefined")
      ) {
        delete o[key];
      } else if (
        o.hasOwnProperty(key) &&
        o[key] !== null &&
        typeof o[key] === "object"
      ) {
        // nested object
        o[key] = stripNull(o[key]);
      }
    }
  }

  return o;
}

// trims string values within an object
export function trim(o, start = true, end = true) {
  if (o) {
    for (let key in o) {
      if (
        o.hasOwnProperty(key) &&
        o[key] !== null &&
        typeof o[key] === "string"
      ) {
        // trim start?
        if (start) {
          o[key] = o[key].trimStart();
        }

        // trim end?
        if (end) {
          o[key] = o[key].trimEnd();
        }

        // if it ends up as an empty string, null it out
        if (o[key] === "") {
          delete o[key];
        }
      } else if (
        o.hasOwnProperty(key) &&
        o[key] !== null &&
        typeof o[key] === "object"
      ) {
        // nested object
        o[key] = trim(o[key], start, end);
      }
    }
  }

  return o;
}

// https://developer.mozilla.org/en/docs/Web/JavaScript/Guide/Regular_Expressions#Using_special_characters
export function escapeRegexCharacters(s) {
  return s.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");
}

// starts a download by simulating a link click
export function startDownload(name, mimeType, base64Content) {
  // to support large content (> 2MB) in Chrome, we need
  // to convert to blob; data URIs dont' work
  const toBlob = (mimeType, content) => {
    // decode
    const binStr = atob(content);

    // put it in an array
    const arr = new Uint8Array(binStr.length);
    for (var i = 0; i < binStr.length; i++) {
      arr[i] = binStr.charCodeAt(i);
    }

    // convert to blob
    return new Blob([arr], {
      type: mimeType,
    });
  };

  // build download link
  const link = document.createElement("a");
  link.download = name;

  // if the content is too big, we need to convert to a blob
  let content = base64Content;
  if (content.length > 1024 * 1024 * 1.9) {
    link.href = URL.createObjectURL(toBlob(mimeType, content));
    link.onclick = function () {
      requestAnimationFrame(function () {
        URL.revokeObjectURL(link.href);
      });
    };
  } else {
    link.href = `data:${mimeType};base64,` + content;
  }

  // fire the download
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}

// converts an object to a query string
export function toQueryString(o) {
  if (!isEmpty(o)) {
    var str = [];
    for (var p in o)
      if (o.hasOwnProperty(p)) {
        str.push(encodeURIComponent(p) + "=" + encodeURIComponent(o[p]));
      }
    return str.join("&");
  } else {
    return "";
  }
}

// translates a duration of minutes to hours,
// trimming to two decimal places if necessary
export function minutesToHours(minutes) {
  // get exact hours
  let hours = minutes / 60;

  // if a round number, use it
  if (hours % 1 === 0) {
    return hours.toString();
  }

  // slim to two decimal places
  hours = hours.toFixed(2);

  // if less than one, strip the leading '0.'
  if (hours < 1) {
    return hours.toString().substring(1);
  } else {
    return hours.toString();
  }
}

// capitalizes the first letter
export function capitalizeFirst(s) {
  if (s && s.toString().length > 0) {
    return (
      s.toString().substring(0, 1).toUpperCase() + s.toString().substring(1)
    );
  } else {
    return s;
  }
}

// splits a really long line, maintaining complete words
export function splitString(s, maxWidth) {
  // we're going to create sections
  var sections = [];

  // first, split into individual words
  var words = s.split(" ");

  // for each word...
  var temp = "";
  words.forEach(function (item, index) {
    if (temp.length > 0) {
      // add a space
      var concat = temp + " " + item;

      // have we exceeded the max?
      if (concat.length > maxWidth) {
        // capture the section
        sections.push(temp);
        temp = "";
      } else {
        if (index === words.length - 1) {
          sections.push(concat);
          return;
        } else {
          temp = concat;
          return;
        }
      }
    }

    // last word
    if (index === words.length - 1) {
      sections.push(item);
      return;
    }

    // have we exceeded the max?
    if (item.length < maxWidth) {
      temp = item;
    } else {
      sections.push(item);
    }
  });

  return sections;
}

// determines if an activity is on site based on its type
export function isActivityOnSite(type) {
  return ["harambee", "onSiteActivity"].includes(type);
}
