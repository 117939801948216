import React from "react";
import { connect } from "react-redux";
import { withLocalize } from "react-localize-redux";

import BasePage from "common/components/Page";
import PageHeader from "common/components/PageHeader";
import { BreadcrumbsItem } from "common/components/Breadcrumbs";
import Content from "common/components/Content";
import i18n from "./i18n.json";

/* Terms of use. */
class TermsOfUse extends BasePage {
  constructor(props) {
    // parent
    super(props);

    // load translations
    props.addTranslation(i18n);
  }

  render() {
    // parent
    super.render();

    // render
    return (
      <div>
        {this.props.location && (
          <div>
            <BreadcrumbsItem
              to={this.props.location.pathname}
              text={this.props.translate("termsOfUse.header")}
            />
            <PageHeader text={this.props.translate("termsOfUse.header")} />
          </div>
        )}

        <Content
          name="web.guardian.siteTermsOfUse"
          errorText={this.props.translate("termsOfUse.errorLoading")}
          onSuccess={() => {
            this.setState({ textLoaded: true });
          }}
          onError={() => {
            this.setState({ textLoaded: false });
          }}
        />
      </div>
    );
  }
}

// turn this into a container component
TermsOfUse = withLocalize(connect()(TermsOfUse));

export default TermsOfUse;
