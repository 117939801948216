// action types
export const SHOW = "busy/SHOW";
export const HIDE = "busy/HIDE";

// show the busy indicator
export const doShow = (id = null) => ({
  type: SHOW,
  id: id,
});

/**
 * Shows the busy indicator. Returns a random ID for this invocation which callers
 * should pass back to ensure they don't hide someone else's indicator.
 */
export function showBusy() {
  // using thunk middleware, so we return a function
  return (dispatch, getState) => {
    // generate an ID
    const id = Math.floor(Math.random() * 10000000);

    // do the call
    dispatch(doShow(id));

    // return the ID
    return id;
  };
}

// actually hide the busy indicator
const doHide = (id = null) => ({
  type: HIDE,
  id: id,
});

/**
 * Hides the busy indicator with a specified ID. If no ID is provided, any active
 * indicators will be hidden.
 *
 * To prevent quick flashes, makes sure it has been displayed for a minimum amount
 * of time before doing so.
 */
export function hideBusy(id = null, minimum = 250) {
  // using thunk middleware, so we return a function
  return (dispatch, getState) => {
    // how long has the indicator been displayed?
    const elapsed =
      getState().busy.busyStart != null
        ? Date.now() - getState().busy.busyStart
        : 0;

    // calculate how much longer we need; a negative result is fine
    const delay = minimum - elapsed;

    // wrap the hide in a timeout
    return setTimeout(() => {
      dispatch(doHide(id));
    }, delay);
  };
}
