import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withLocalize } from "react-localize-redux";
import requiredIf from "react-required-if";

import BasePage from "common/components/Page";
import { showBusy, hideBusy } from "common/components/Busy/actions";
import Retry from "common/components/Retry";
import Loading from "common/components/Loading";
import { fetchSnippetByName as getContent } from "common/entities/Content/actions";
import "./styles.scss";

/* External content. */
class Content extends BasePage {
  constructor(props) {
    // parent
    super(props);

    // for content management
    this.state = {
      ...this.state,
      content: null,
    };
  }

  componentDidMount() {
    // parent
    super.componentDidMount();

    // load all necessary data
    this.loadData(this.props);
  }

  componentDidUpdate(prevProps, prevState) {
    // parent
    super.componentDidUpdate(prevProps, prevState);

    // if the language changed, re-fetch the content
    if (
      this.props.activeLanguage &&
      prevProps.activeLanguage &&
      this.props.activeLanguage.code !== prevProps.activeLanguage.code
    ) {
      // reload
      this.loadData(this.props);
    }
  }

  loadData(props) {
    // clear any existing content
    this.setState({ content: null });

    // run our loaders
    super.loadData(
      props.getContent(
        props.name,
        props.activeLanguage ? props.activeLanguage.code : null,
        props.showBusy
      ),
      (results) => {
        // the results are in the same order as the calls
        const content = results[0];

        // if we have a success callback, call it
        if (props.onSuccess) {
          props.onSuccess();
        }

        // return the updated state
        return {
          content: content,
        };
      },
      () => {
        // if we have an error callback, call it
        if (props.onError) {
          props.onError();
        }
      }
    );
  }

  render() {
    // parent
    super.render();

    // render
    return (
      <div>
        {/* load error? */}
        {this.props.showError && super.dataLoadError() && (
          <Retry
            onRefresh={() => this.loadData(this.props)}
            messageText={this.props.errorText}
          />
        )}

        {/* still loading? */}
        {(this.props.showLoader ||
          typeof this.props.showLoader === "undefined") &&
          super.dataLoading() && (
            <div className="container">
              <div className="row">
                <div className="col-12 text-center fsp-content-loading">
                  <Loading />
                </div>
              </div>
            </div>
          )}

        {/* found content? */}
        {!super.dataLoading() && this.state.content && (
          <div
            className="fsp-content-content"
            dangerouslySetInnerHTML={{ __html: this.state.content.content }}
          />
        )}
      </div>
    );
  }
}

// map dispatch function to callback props so that the component can invoke them
const mapDispatchToProps = (dispatch) => ({
  // getContent
  getContent: (name, locale, showBusyIndicator = false) => {
    // show the busy indicator
    let busyId = null;
    if (showBusyIndicator) {
      busyId = dispatch(showBusy());
    }

    // get the content
    return dispatch(getContent(name, locale)).finally(() => {
      // hide the busy indicator
      if (busyId) {
        dispatch(hideBusy(busyId));
      }
    });
  },
});

// turn this into a container component
Content = withLocalize(connect(null, mapDispatchToProps)(Content));

// set prop types and required-ness
Content.propTypes = {
  name: PropTypes.string.isRequired,
  showLoader: PropTypes.bool,
  showError: PropTypes.bool,
  showBusy: PropTypes.bool,
  errorText: requiredIf(PropTypes.string, (props) => props.showError),
  onError: PropTypes.func,
  onSuccess: PropTypes.func,
};

// set default props
Content.defaultProps = {
  showLoader: true,
  showError: true,
  showBusy: false,
};

export default Content;
