import * as a from "./actions";

// define the default state
const defaultState = { crumbs: [{ to: "/" }] };

// define the reducer
export const breadcrumbs = (state = defaultState, action) => {
  switch (action.type) {
    // clears all crumbs back to the root
    case a.CLEAR:
      // clear back to the root
      if (state.crumbs.length > 1) {
        return {
          ...state,
          crumbs: [...state.crumbs.slice(0, 1)],
        };
      } else {
        return state;
      }

    // removes the tip
    case a.REMOVE_TIP:
      // as long as the tip isn't the root, clear it
      if (state.crumbs.length > 1) {
        return {
          ...state,
          crumbs: [...state.crumbs.slice(0, state.crumbs.length - 1)],
        };
      } else {
        return state;
      }

    // registers a new breadcrumb
    case a.REGISTER:
      // if we already have this crumb, we trim the list back to it and update the text
      for (let i = 0; i < state.crumbs.length; i++) {
        const a = state.crumbs[i].to;
        const b = action.to;
        if (a === b) {
          return {
            ...state,
            crumbs: [
              ...state.crumbs.slice(0, i),
              { ...state.crumbs[i], text: action.text },
            ],
          };
        } else {
          // if they are identical in every manner save for
          // the ID at the end, we consider them a match
          if (
            a.includes("/") &&
            !a.endsWith("/") &&
            b.includes("/") &&
            !b.endsWith("/")
          ) {
            const aSuffix = a.substr(a.lastIndexOf("/") + 1);
            const bSuffix = b.substr(b.lastIndexOf("/") + 1);
            if (/^\d+$/.test(aSuffix) && /^\d+$/.test(bSuffix)) {
              // the suffixes are both numbers; now see if everything else matches
              if (
                a.substr(0, a.lastIndexOf("/")) ===
                b.substr(0, b.lastIndexOf("/"))
              ) {
                // they match
                return {
                  ...state,
                  crumbs: [
                    ...state.crumbs.slice(0, i),
                    { ...state.crumbs[i], text: action.text },
                  ],
                };
              }
            }
          }

          // if they are identical in every manner save for
          // a tab, we consider them a match and replace
          // the tip with the updated tab
          let bareA = a.includes("!") ? a.substring(0, a.indexOf("!")) : a;
          let bareB = b.includes("!") ? b.substring(0, b.indexOf("!")) : b;
          if (bareA === bareB) {
            return {
              ...state,
              crumbs: [
                ...state.crumbs.slice(0, i),
                { ...state.crumbs[i], to: action.to, text: action.text },
              ],
            };
          }
        }
      }

      // sanity check
      if (!state.crumbs) {
        state.crumbs = defaultState.crumbs;
      } else if (!Array.isArray(state.crumbs)) {
        state.crumbs = [state.crumbs];
      }

      // add it
      return {
        ...state,
        crumbs: [...state.crumbs, { to: action.to, text: action.text }],
      };

    // returns the current state
    default:
      return state;
  }
};
