import { getSessionId } from "common/util/session";
import * as errors from "./util/errors";
import { unauthenticated } from "./entities/Security/actions";
import { sendSOS } from "./entities/SOS/actions";
import { registerSession } from "common/util/session";
import { stripNull, trim } from "./util/index";
import { storeOnContext } from "./entities/Context/actions";
import { addAlert } from "./components/Alerts/actions";

// defines options, interceptors, and handlers for Axios middleware
export const axiosOptions = {
  // we intercept all calls for logging and to inject the session token
  interceptors: {
    request: [
      ({ getState, dispatch, getSourceAction }, request) => {
        // log the request
        if (request.data) {
          console.debug(
            `Request being made to ${request.url} with data:`,
            request.data
          );
        } else {
          console.debug(
            `Request being made to ${request.url} with an empty payload`
          );
        }

        // if we have a session ID, add it as a header
        const token = getSessionId();
        if (token) {
          request.headers = {
            ...request.headers,
            "X-Session-Token": token,
          };
        }

        // if we have a payload, we trim the data
        if (request.data) {
          request.data = trim(request.data);
        }

        // if we have a payload, we need to make sure there are no null values
        if (request.data) {
          request.data = stripNull(request.data);
        }

        // propagate the request
        return request;
      },
    ],
  },

  /**
   * We handle our own errors on axios calls. If we receive one or more business errors
   * from the server, we simply propagate them. If we receive an unexpected status code,
   * we intercept it and create our own error to return. That way the client can always
   * expect a well-known response format.
   */
  onError: ({ action, next, error, getState, dispatch }) => {
    // try to figure out the URL
    let url = null;
    if (
      action &&
      action.payload &&
      action.payload.request &&
      action.payload.request.url
    ) {
      url = action.payload.request.url;
    } else if (
      error &&
      error.response &&
      error.response.request &&
      error.response.request.responseURL
    ) {
      url = error.response.request.responseURL;
    }

    // try to figure out the payload
    let payload = "[unknown]";
    if (
      action &&
      action.payload &&
      action.payload.request &&
      action.payload.request.data
    ) {
      payload = JSON.stringify(action.payload.request.data);
    }

    // log the error
    if (url) {
      console.error(
        "Error response (status " +
          (error && error.response && error.response.status
            ? error.response.status
            : "unknown") +
          `) received from request to ${url}`,
        error && error.response ? error.response : ""
      );

      // ignore session operations
      if (url.includes("/sessions/")) {
        return;
      }

      // if this is an error trying to send an SOS, we need to break out to avoid an infinate loop
      if (url.includes("/sos")) {
        return;
      }
    } else {
      console.error(
        "Error response (status " +
          (error && error.response && error.response.status
            ? error.response.status
            : "unknown") +
          `) received from request to unknown URL`,
        error && error.response ? error.response : ""
      );
    }

    // do we have a non-403 business error embedded in the response?
    if (
      error &&
      error.response &&
      error.response.data &&
      error.response.data.code &&
      error.response.status !== 403
    ) {
      // let the errors fall through for rejection
      return error.response.data;
    } else if (error && error.message) {
      // there is one that we treat differently
      switch (error.message) {
        // client connectivity issue; all we can do is alert the user
        case "Network Error":
          // we need to do our own localization here
          const language = window.$localStorage.getItem("language")
            ? window.$localStorage.getItem("language")
            : "en";
          let message =
            "It looks like you don't have a connection right now! Please try again once you are reconnected.";
          const state = getState();
          if (
            state.localize &&
            state.localize.languages &&
            state.localize.translations &&
            state.localize.translations["error.networkError"]
          ) {
            let index = 0;
            for (let i = 0; i < state.localize.languages.length; i++) {
              if (
                state.localize.languages[i].code &&
                state.localize.languages[i].code === language
              ) {
                index = i;
                break;
              }
            }
            if (
              state.localize.translations["error.networkError"].length >=
              index + 1
            ) {
              message =
                state.localize.translations["error.networkError"][index];
            }
          }

          // fire the message
          dispatch(addAlert("error", message, 10000));

          // clients can handle this if desired
          return {
            code: errors.NETWORK_ERROR,
            message: "Network error",
          };

        default:
          // do nothing
          break;
      }
    }

    // no business error; look at response codes
    if (error && error.response) {
      switch (error.response.status) {
        // bad request
        case 400:
          // send an SOS for this one
          dispatch(
            sendSOS(
              null,
              error.response.status +
                " received on call to: " +
                url +
                " with payload: " +
                payload +
                "; this indicates an error formatting the request by the client"
            )
          );

          // this will be handled later
          return {
            code: errors.BAD_REQUEST,
            message: error.response.statusText
              ? error.response.statusText
              : "Bad request",
          };

        // not authenticated
        case 401:
          // this is a special case; unless this was a login or password reset request, we
          // handle this by logging the user out and kicking to the login page
          if (
            url &&
            !url.endsWith("/authenticate") &&
            !url.includes("/oauth/authenticate") &&
            !url.endsWith("/setCredentials") &&
            !url.endsWith("/credentials")
          ) {
            // we need to do our own localization here
            const language = window.$localStorage.getItem("language")
              ? window.$localStorage.getItem("language")
              : "en";
            let message =
              "Your session is no longer valid. Please login again.";
            const state = getState();
            if (
              state.localize &&
              state.localize.languages &&
              state.localize.translations &&
              state.localize.translations["error.notAuthenticated"]
            ) {
              let index = 0;
              for (let i = 0; i < state.localize.languages.length; i++) {
                if (
                  state.localize.languages[i].code &&
                  state.localize.languages[i].code === language
                ) {
                  index = i;
                  break;
                }
              }
              if (
                state.localize.translations["error.notAuthenticated"].length >=
                index + 1
              ) {
                message =
                  state.localize.translations["error.notAuthenticated"][index];
              }
            }

            // fire the message and log the user out
            dispatch(
              unauthenticated(
                message,
                window.location
                  ? window.location.pathname +
                      (window.location.search ? window.location.search : "")
                  : null
              )
            );
          }

          // this will be handled later
          return {
            code: errors.NOT_AUTHENTICATED,
            message: "Authentication error",
          };

        // authenticated but not authorized
        case 403:
          // we need to do our own localization here
          const language = window.$localStorage.getItem("language")
            ? window.$localStorage.getItem("language")
            : "en";
          let message =
            "You aren't allowed to do that! Unfortunately, trying again won't do any good. If you think this is incorrect, please contact support for assistance.";
          const state = getState();
          if (
            state.localize &&
            state.localize.languages &&
            state.localize.translations &&
            state.localize.translations["error.notAuthorized"]
          ) {
            let index = 0;
            for (let i = 0; i < state.localize.languages.length; i++) {
              if (
                state.localize.languages[i].code &&
                state.localize.languages[i].code === language
              ) {
                index = i;
                break;
              }
            }
            if (
              state.localize.translations["error.notAuthorized"].length >=
              index + 1
            ) {
              message =
                state.localize.translations["error.notAuthorized"][index];
            }
          }

          // fire the message
          dispatch(addAlert("error", message, 10000));

          // this will be handled later
          return {
            code: errors.NOT_AUTHORIZED,
            message: "Authorization error",
          };

        // not found; check for business payload
        case 404:
          // this will be handled later
          return {
            code: errors.NOT_FOUND,
            message: error.response.statusText
              ? error.response.statusText
              : "Not found",
          };

        // forbidden, method not allowed
        case 405:
          // send an SOS for this one
          dispatch(
            sendSOS(
              null,
              error.response.status +
                " received on call to: " +
                url +
                " with payload: " +
                payload +
                "; this indicates a server configuration problem"
            )
          );

          // this will be handled later
          return {
            code: errors.FORBIDDEN,
            message: error.response.statusText
              ? error.response.statusText
              : "Forbidden call",
          };

        // request couldn't be processed
        case 422:
          // send an SOS for this one
          dispatch(
            sendSOS(
              null,
              error.response.status +
                " received on call to: " +
                url +
                " with payload: " +
                payload
            )
          );
          break;

        // server errors
        case 500:
        case 501:
        case 503:
        case 506:
          // send an SOS for this one
          dispatch(
            sendSOS(
              null,
              error.response.status +
                " received on call to: " +
                url +
                " with payload: " +
                payload
            )
          );

          // this will be handled later
          return {
            code: errors.SERVER_ERROR,
            message: error.response.statusText
              ? error.response.statusText
              : "Unknown server error",
          };
        default:
        // do nothing
      }
    }

    // let the error fall through for rejection
    return error;
  },

  // we want to check successful responses for errors too
  onSuccess: ({ action, next, response, getState, dispatch }) => {
    // special case: updated token
    if (response && response.headers) {
      // look for a token
      let token = null;
      if (
        response.headers["X-Session-Token"] &&
        response.headers["X-Session-Token"] !== ""
      ) {
        token = response.headers["X-Session-Token"];
      } else if (response.headers["x-session-token"]) {
        token = response.headers["x-session-token"];
      }

      // if we have one, store it
      if (token) {
        registerSession(token);
        console.debug("Received (and applied) updated session token: " + token);

        // force a re-render
        dispatch(storeOnContext("token", Math.floor(Math.random() * 10000000)));
      }
    }

    // log the response
    try {
      if (typeof response.data === "boolean") {
        console.debug(
          "Response received from request to " +
            (response.request && response.request.responseURL
              ? response.request.responseURL
              : "unknown URL"),
          response.data
        );
      } else if (!response || !response.data) {
        console.debug(
          "Empty response received from request to " +
            (response.request && response.request.responseURL
              ? response.request.responseURL
              : "unknown URL")
        );
      } else if (response && response.data) {
        if (typeof response.data === "string") {
          console.debug(
            "Response received from request to " +
              (response.request && response.request.responseURL
                ? response.request.responseURL
                : "unknown URL"),
            response.data.length <= 512
              ? response.data
              : response.data.substring(0, 512) + "..."
          );
        } else if (typeof response.data === "object") {
          console.debug(
            "Response received from request to " +
              (response.request && response.request.responseURL
                ? response.request.responseURL
                : "unknown URL"),
            response.data
          );
        } else {
          console.debug(
            "Binary response received from request to " +
              (response.request && response.request.responseURL
                ? response.request.responseURL
                : "unknown URL")
          );
        }
      }
    } catch (e) {
      // don't let this tank processing
      console.error("Error logging response", e);
    }

    // check for business errors
    if (response && response.data && response.data.errors) {
      return Promise.reject(response.data.errors);
    }

    // default
    if (response && (typeof response.data === "boolean" || response.data)) {
      return response.data;
    } else {
      return null;
    }
  },

  // we want errors to result in a rejected promise
  returnRejectedPromiseOnError: true,
};
