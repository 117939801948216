import React from "react";
import PropTypes from "prop-types";

import { BasePureComponent } from "common/components/Base";
import "./styles.scss";

/* A page header. */
class PageHeader extends BasePureComponent {
  render() {
    // parent
    super.render();

    // render for printing
    return (
      <div className="d-none d-print-block">
        <div className="fsp-page-header">{this.props.text}</div>
        <span className="fsp-page-header-separator" />
      </div>
    );
  }
}

// set prop types and required-ness
PageHeader.propTypes = { text: PropTypes.string.isRequired };

// set default props
PageHeader.defaultProps = {};

export default PageHeader;
