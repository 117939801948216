import React from "react";
import { connect } from "react-redux";

import { BasePureComponent } from "common/components/Base";
import "./styles.scss";

/* Busy indicator. */
class Busy extends BasePureComponent {
  constructor(props) {
    // parent
    super(props);

    // don't show immediately
    this.state = {
      ...this.state,
      show: false,
    };
  }

  componentDidUpdate(prevProps, prevState) {
    // parent
    super.componentDidUpdate(prevProps, prevState);

    // if we need to display the indicator, set the flag
    if (!this.state.show && this.props.busy && this.props.busy.length > 0) {
      this.setState({ show: true });
    }

    // if we displayed everything, reset the flag
    else if (
      this.state.show &&
      (!this.props.busy || this.props.busy.length === 0)
    ) {
      this.setState({ show: false });
    }
  }

  render() {
    // parent
    super.render();

    // render
    if (this.state.show && this.props.busy && this.props.busy.length > 0) {
      return (
        <div id="fsp-overlay" className="d-block">
          <div className="fsp-busy">
            <div
              className="progress-bar progress-bar-striped progress-bar-animated fsp-busy-bar fsp-background"
              role="progressbar"
              aria-valuemin="0"
              aria-valuemax="100"
              aria-valuenow="100"
            />
          </div>
        </div>
      );
    } else {
      return null;
    }
  }
}

// map state to properties relevant to this component
function mapStateToProps(state, ownProps) {
  return {
    // busy?
    busy: state.busy ? state.busy.busy : [],
  };
}

// turn this into a container component
Busy = connect(mapStateToProps)(Busy);

// set prop types and required-ness
Busy.propTypes = {};

// set default props
Busy.defaultProps = {};

export default Busy;
