import $ from "jquery";

// sets HTML5 errors for a given field on a given form
export function setFieldError(formId, fieldId, error) {
  // sanity check
  if (!fieldId) {
    return;
  }

  // escape special characters in the ID
  fieldId = fieldId.replace("[", "\\[").replace("]", "\\]").replace(".", "\\.");

  // if we have a form ID, look for the form first
  let selector = `#${fieldId}`;
  if (formId) {
    selector = `#${formId} ${selector}`;
  }

  // if the field exists...
  const field = $(selector);
  if (field && field[0] && error) {
    // ... set it
    if (field[0]) {
      field[0].setCustomValidity(error);
    }
  }
}

// clears HTML5 errors for a given field
export function clearFieldError(formId, fieldId) {
  // sanity check
  if (!fieldId) {
    return;
  }

  // escape special characters in the ID
  fieldId = fieldId.replace("[", "\\[").replace("]", "\\]").replace(".", "\\.");

  // if we have a form ID, look for the form first
  let selector = `#${fieldId}`;
  if (formId) {
    selector = `#${formId} ${selector}`;
  }

  // if the field exists...
  const field = $(selector);
  if (field && field[0]) {
    // ... clear it
    if (field[0]) {
      field[0].setCustomValidity(``);
    }
  }
}

// password format validator
export const verifyPasswordFormat = (
  formId,
  fieldId,
  errorPrefix,
  translate,
  password
) => {
  let error;

  // bail if nothing to validate
  if (!password) {
    return;
  }

  // length
  if ((!error && password.length < 8) || password.length > 32) {
    error = translate(errorPrefix + ".field.password.invalid.length");
  }

  // uppercase
  if (!error && !/(?=.*[A-Z])/.test(password)) {
    error = translate(errorPrefix + ".field.password.invalid.upper");
  }

  // lowercase
  if (!error && !/(?=.*[a-z])/.test(password)) {
    error = translate(errorPrefix + ".field.password.invalid.lower");
  }

  // number
  if (!error && !/(?=.*\d)/.test(password)) {
    error = translate(errorPrefix + ".field.password.invalid.number");
  }

  // special
  if (!error && !/(?=.*[-+_!@#$%^&*.,?|;:'"<>/()[\]`~\\{}])/.test(password)) {
    error = translate(errorPrefix + ".field.password.invalid.special");
  }

  // error?
  if (error) {
    // set HTML5 error
    setFieldError(formId, fieldId, error);

    // set form error
    return error;
  } else {
    // clear HTML5 error
    clearFieldError(formId, fieldId);
  }
};

// password match check
export const verifyPasswordsMatch = (
  formId,
  fieldId,
  errorPrefix,
  translate,
  passwordField,
  confirmPassword
) => {
  // if both are set, make sure the passwords match
  const password = document.querySelector(passwordField)
    ? document.querySelector(passwordField).value
    : null;
  if (password && confirmPassword && password !== confirmPassword) {
    // set HTML5 error
    setFieldError(
      formId,
      fieldId,
      translate(errorPrefix + ".field.confirmPassword.mismatch")
    );

    // set form error
    return translate(errorPrefix + ".field.confirmPassword.mismatch");
  } else {
    // clear HTML5 error
    clearFieldError(formId, fieldId);
  }
};

// checks form HTML5 validity; optionally uses a slight delay to allow dynamic fields to render
export const checkValidity = (formObject, delay = true) => {
  // TODO: This is a kludge; we'd be better of rolling
  //       a custom Form class to hold this
  if (formObject.props && formObject.props.form) {
    if (delay) {
      const cvThis = formObject;
      setTimeout(function () {
        cvThis.setState({
          htmlValid: document.querySelector("#" + cvThis.props.form)
            ? document.querySelector("#" + cvThis.props.form).checkValidity()
            : false,
        });
      }, 200);
    } else {
      formObject.setState({
        htmlValid: document.querySelector("#" + formObject.props.form)
          ? document.querySelector("#" + formObject.props.form).checkValidity()
          : false,
      });
    }
  }
};
