import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withLocalize } from "react-localize-redux";

import { BasePureComponent } from "common/components/Base";
import i18n from "./i18n.json";

/* An alert. Can be of flavor "info", "success", "warning", or "error". */
class Alert extends BasePureComponent {
  constructor(props) {
    // parent
    super(props);

    // capture props
    this.flavor = props.flavor != null ? props.flavor : "info";

    // load translations
    props.addTranslation(i18n);

    // figure out the class based on the flavor
    this.typeClass = null;
    switch (this.flavor.toUpperCase()) {
      case "ERROR":
        this.typeClass = "alert-danger";
        break;
      case "WARN":
      case "WARNING":
        this.typeClass = "alert-warning";
        break;
      case "INFO":
        this.typeClass = "alert-info";
        break;
      case "SUCCESS":
        this.typeClass = "alert-success";
        break;
      default:
        this.typeClass = "alert-info";
        break;
    }

    // the callback when manually closed
    this.onClose = props.onClose;
  }

  render() {
    // parent
    super.render();

    // render
    return (
      // extra <div>: https://github.com/facebook/react/issues/6802#issuecomment-257875047
      <div>
        <div
          className={`alert ${this.typeClass} alert-dismissible fade show`}
          id={this.id}
          role="alert"
        >
          {this.props.text instanceof String ? (
            <span
              dangerouslySetInnerHTML={{
                __html: this.props.text,
              }}
            />
          ) : (
            this.props.text
          )}
          <button
            tye="button"
            className="close"
            data-dismiss="alert"
            aria-label={this.props.translate("alert.button.close.title")}
            onClick={this.props.onClose}
            title={this.props.translate("alert.button.close.title")}
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
      </div>
    );
  }
}

// turn this into a container component
Alert = withLocalize(connect()(Alert));

// set prop types and required-ness
Alert.propTypes = {
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
  onClose: PropTypes.func.isRequired,
};

// set default props
Alert.defaultProps = {};

export default Alert;
