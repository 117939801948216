//
// This file contains application-specific variables, some of which
// are pulled via callbacks from common components.
//

// default page title
export const defaultTitle = "FSPConnect - Scholar Portal";

// default size for form widgets, in Bootstrap parlance ('sm' or 'lg')
export const formWidgetSize = "lg";

// default size for buttons, in Bootstrap parlance ('sm' or 'lg')
export const formButtonSize = "lg";

// default page size for lists
export const defaultPageSize = 25;

// include weekends in calendar-based functionality?
export const includeWeekends = false;

// the type of entity that uses this application
export const entityType = "guardians";
