import { createStore, applyMiddleware, compose } from "redux";
import { connectRouter, routerMiddleware } from "connected-react-router";
import { persistReducer, persistStore } from "redux-persist";
import storage from "redux-persist/lib/storage/session";
import axios from "axios";
import Cookies from "js-cookie";
import thunk from "redux-thunk";
import { createCookieMiddleware } from "redux-cookie";
import axiosMiddleware from "redux-axios-middleware";

import { axiosOptions } from "./Middleware";
import rootReducer from "./reducers";

// persist some of the local state across refreshes
const persistConfig = {
  key: "root",
  storage,
  whitelist: ["context", "breadcrumbs"],
};

// create axios client
const client = axios.create({
  baseURL: process.env.REACT_APP_HUB,
  responseType: "json",
});

export default (history) => {
  // create a persist reducer that wraps the root reducer
  const persistedReducer = persistReducer(persistConfig, rootReducer(history));

  // prepare to compose enhancers
  const composeEnhancers =
    process.env.REACT_APP_ENV === "prod" ||
    !window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
      ? compose
      : window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
          trace: true,
          traceLimit: 25,
        }) || compose;

  // create the store with the middleware enhancers
  const store = createStore(
    connectRouter(history)(persistedReducer),
    composeEnhancers(
      applyMiddleware(
        thunk,
        routerMiddleware(history),
        createCookieMiddleware(Cookies),
        axiosMiddleware(client, axiosOptions)
      )
    )
  );

  // create the associated persistor
  const persistor = persistStore(store);

  // return both
  return { store, persistor };
};
