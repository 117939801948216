import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { withLocalize, Translate } from "react-localize-redux";

import { BasePureComponent } from "./components/Base";
import { sendSOS } from "common/entities/SOS/actions";
import broken from "./images/broken.png";
import i18n from "./i18n.json";

/**
 * A boundary for handling errors that no one else handled.
 */
class ErrorBoundary extends BasePureComponent {
  constructor(props) {
    // parent
    super(props);

    // load translations
    props.addTranslation(i18n);

    // keep track of errors
    this.state = {
      ...this.state,
      error: null,
    };
  }

  componentDidCatch(error, info) {
    // parent
    super.componentDidCatch(error, info);

    // log the error
    console.error("Unhandled error caught", error);

    // flag that we have an error
    this.setState({ error: error });
  }

  render() {
    // parent
    super.render();

    // render
    if (this.state.error) {
      // send an SOS for this one
      this.props.sendSOS(this.state.error);

      return (
        <div
          style={{
            display: "table",
            width: "100%",
            height: "90vh",
            padding: "50px",
          }}
        >
          <section
            style={{
              display: "table-row",
            }}
          >
            <div
              className="align-middle text-center"
              style={{
                display: "table-cell",
                maxWidth: "400px",
              }}
            >
              <h2>
                <Translate id="errorBoundary.mainText" />
              </h2>
              <p>
                <img
                  src={broken}
                  alt={this.props.translate("errorBoundary.shatterAlt")}
                  width="360"
                  height="315"
                />
              </p>
              <p>
                <Translate id="errorBoundary.secondaryText" />
              </p>
            </div>
          </section>
        </div>
      );
    }
    return this.props.children;
  }
}

// map dispatch function to callback props so that the component can invoke them
const mapDispatchToProps = (dispatch) => ({
  // send SOS
  sendSOS: (error) => {
    dispatch(sendSOS("Error boundary invoked", error));
  },
});

// make router props accessible; this is necessary to
// drive re-renders based on path changes
ErrorBoundary = withRouter(
  withLocalize(connect(null, mapDispatchToProps)(ErrorBoundary))
);

// set prop types and required-ness
ErrorBoundary.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
    PropTypes.array,
  ]).isRequired,
};

export default ErrorBoundary;
