import * as a from "./actions";

// define the default state
const defaultState = {
  // navigation destination
  destination: null,
};

// define the reducer
export const navigate = (state = defaultState, action) => {
  switch (action.type) {
    // navigate somewhere
    case a.GO:
      return {
        ...state,
        destination: action.destination,
        supplemental: action.supplemental,
      };

    // clear navigation
    case a.CLEAR:
      return {
        ...state,
        destination: null,
        supplemental: null,
      };

    // returns the current state
    default:
      return state;
  }
};
