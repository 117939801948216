import React from "react";
import PropTypes from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { BasePureComponent } from "common/components/Base";
import "./styles.scss";

/* A message box for content that needs to stand out. Can be of flavor "info", "warning", or "error" with a default of "info". */
class MessageBox extends BasePureComponent {
  render() {
    // parent
    super.render();

    // figure out the type
    let type = "info";
    if (this.props.flavor.toLowerCase() === "info") {
      type = "info";
    } else if (this.props.flavor.toLowerCase() === "warning") {
      type = "warning";
    } else if (this.props.flavor.toLowerCase() === "error") {
      type = "danger";
    }

    // render
    return (
      <div
        className={
          `fsp-message alert alert-${type}` +
          (this.props.className ? ` ${this.props.className}` : "")
        }
      >
        <div className="fsp-message-box container-fluid">
          <div className="d-flex">
            <div className="mr-2">
              {type === "warning" || type === "danger" ? (
                <FontAwesomeIcon icon="exclamation-circle" />
              ) : (
                <FontAwesomeIcon icon="info-circle" />
              )}
            </div>
            <div className="flex-grow-1 text-left">{this.props.children}</div>
          </div>
        </div>
      </div>
    );
  }
}

// set prop types and required-ness
MessageBox.propTypes = {
  flavor: PropTypes.string,
  className: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
    PropTypes.array,
  ]).isRequired,
};

// set default props
MessageBox.defaultProps = { flavor: "info" };

export default MessageBox;
