import { withRouter } from "react-router-dom";

import { BasePureComponent } from "common/components/Base";
import { scrollToTop } from "common/util";

/* Helper to scroll to top on navigation. */
class ScrollToTop extends BasePureComponent {
  // scroll to the top on update
  componentDidUpdate(prevProps) {
    // parent
    super.componentDidUpdate(prevProps);

    // scroll to top
    if (this.props.location !== prevProps.location) {
      scrollToTop();
    }
  }

  // this component doesn't add any rendering
  render() {
    return this.props.children;
  }
}

// make router props accessible; this is necessary to
// drive re-renders based on path changes
export default withRouter(ScrollToTop);
