import React from "react";
import Loadable from "react-loadable";

import Loading from "common/components/Loading";
import Retry from "common/components/Retry";

/**
 * Allows a component to be loaded asynchronously.
 */
function ComponentLoading(props) {
  if (props.error) {
    // error
    return (
      <div className="row">
        <div className="col">
          <Retry onRefresh={props.retry} />
        </div>
      </div>
    );
  } else if (props.pastDelay) {
    // loading...
    return (
      <div className="row">
        <div className="col-12 text-center fsp-content-loading">
          <Loading className="mt-3 mb-3" />
        </div>
      </div>
    );
  } else {
    // either still loading or loaded
    return null;
  }
}

// convenience function to create a loadable component
export const createLoadable = (loader, opts = {}) =>
  Loadable(
    Object.assign(
      {
        loading: ComponentLoading,
      },
      { loader: loader, ...opts }
    )
  );
