import React from "react";
import PropTypes from "prop-types";
import { withLocalize } from "react-localize-redux";

import { BasePureComponent } from "common/components/Base";
import i18n from "./i18n.json";
import "./styles.scss";

/* A Bootstrap modal. */
class Modal extends BasePureComponent {
  constructor(props) {
    // parent
    super(props);

    // load translations
    props.addTranslation(i18n);
  }

  render() {
    // parent
    super.render();

    // render
    return (
      <div
        className="modal fade"
        id={`${this.props.id}`}
        tabIndex="-1"
        role="dialog"
        aria-labelledby={`${this.props.id}-title`}
        aria-hidden="true"
        data-backdrop={
          !this.props.showOverlay
            ? false
            : this.props.showClose
            ? "static"
            : false
        }
      >
        <div
          className={`modal-dialog modal-${this.props.size}`}
          role="document"
        >
          <div className="modal-content">
            {(this.props.header || this.props.showClose) && (
              <div className="modal-header">
                {this.props.header}
                {this.props.showClose && (
                  <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-label={this.props.translate(
                      "modal.button.close.title"
                    )}
                    title={this.props.translate("modal.button.close.title")}
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                )}
              </div>
            )}
            <div className="modal-body">{this.props.children}</div>
          </div>
        </div>
      </div>
    );
  }
}

// turn this into a container component
Modal = withLocalize(Modal);

// set prop types and required-ness
Modal.propTypes = {
  id: PropTypes.string.isRequired,
  size: PropTypes.string,
  header: PropTypes.string,
  showClose: PropTypes.bool,
  showOverlay: PropTypes.bool,
  children: PropTypes.element.isRequired,
};

// set default props
Modal.defaultProps = { size: "lg", showClose: true, showOverlay: true };

export default Modal;
