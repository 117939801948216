// action types
export const OPEN = "menu/OPEN";
export const CLOSE = "menu/CLOSE";
export const TOGGLE = "menu/TOGGLE";

// open the menu
export const open = () => ({
  type: OPEN,
});

// close the menu
export const close = () => ({
  type: CLOSE,
});

// toggle the menu
export const toggle = () => ({
  type: TOGGLE,
});
