import jwtDecode from "jwt-decode";

// keep a local copy of the decoded JWT
let _decoded = null;

// Decodes a session token and extracts a value from it.
// If a token is not provided, the stored token will be used.
function extractFromSessionToken(key, token = null) {
  // if using the stored token, see if we have it decoded and cached
  if (!token && _decoded) {
    // return the desired value
    return _decoded[key];
  } else {
    // get the stored token
    const toDecode = !token ? getSessionToken() : token;

    // do we have one?
    if (toDecode) {
      try {
        // decode it
        const decoded = jwtDecode(toDecode);

        // if using the stored token, cache the decoded values
        if (!token) {
          _decoded = decoded;
        }

        // return the desired value
        return decoded[key];
      } catch (e) {
        // log it
        console.error("Error decoding JWT: " + toDecode, e);
      }
    }
  }

  return null;
}

// Gets the logged in user's session ID. We decode the stored
// JWT every time to make sure that the user cannot mess with it.
export function getSessionId(token = null) {
  return extractFromSessionToken("sid", token);
}

// Gets the logged in user's ID. We decode the stored JWT every time
// to make sure that the user cannot mess with it.
export function getUserId(token = null) {
  return extractFromSessionToken("sub_id", token);
}

// Detects if the logged in user is an admin.
export function isAdmin(token = null) {
  return getUserType(token) === "administrator";
}

// Gets the type of logged in user.
export function getUserType(token = null) {
  return extractFromSessionToken("type", token);
}

// Gets the logged in user's permissions. We decode the stored JWT every time
// to make sure that the user cannot mess with it.
export function getUserPermissions(token = null) {
  // get the perms
  const perms = extractFromSessionToken("perms", token);

  // if we got some, parse them
  if (perms) {
    return JSON.parse(perms);
  }
}

// Checks if the logged in user has a specific permission
export function hasPermission(name, target = null, token = null) {
  // this is only relevant for administrators
  if (!isAdmin(token)) {
    return true;
  }

  // sanity check
  if (!name) {
    return true;
  }

  // remove this to reintroduce volunteers
  if (["viewVolunteers", "manageVolunteers"].includes(name)) {
    return false;
  }

  // get all permissions
  const permissions = getUserPermissions(token);

  // there is one oddball; 'viewSites' really means
  // "has view permission for at least one site"
  if (permissions && name === "viewSites") {
    for (let i = 0; i < permissions.length; i++) {
      if (permissions[i].Name === "viewSite") {
        return permissions[i].Granted;
      }
    }
  } else {
    // do we have the desired permission?
    if (permissions) {
      for (let i = 0; i < permissions.length; i++) {
        if (
          permissions[i].Name === name &&
          (!permissions[i].Target ||
            (target && permissions[i].Target === target.toString()) ||
            (!target && permissions[i].Target === ""))
        ) {
          return permissions[i].Granted;
        }
      }
    }
  }

  return false;
}

// Checks if the logged in user has any out of a set of
// permissions; this only works with targetless permissions
export function hasAnyPermission(names, token = null) {
  // this is only relevant for administrators
  if (!isAdmin()) {
    return true;
  }

  // we only deal with arrays
  if (!Array.isArray(names)) {
    names = [names];
  }

  // get all permissions
  const permissions = getUserPermissions(token);

  // do we have any of the desired permissions?
  if (permissions) {
    for (let i = 0; i < names.length; i++) {
      if (hasPermission(names[i], token)) {
        return true;
      }
    }
  }

  return false;
}

// Checks if the logged in user has all out of a set of
// permissions; this only works with targetless permissions
export function hasAllPermissions(names, token = null) {
  // this is only relevant for administrators
  if (!isAdmin()) {
    return true;
  }

  // we only deal with arrays
  if (!Array.isArray(names)) {
    names = [names];
  }

  // get all permissions
  const permissions = getUserPermissions(token);

  // do we have any of the desired permissions?
  if (permissions) {
    for (let i = 0; i < names.length; i++) {
      if (!hasPermission(names[i], token)) {
        return false;
      }
    }

    return true;
  }

  return false;
}

// Registers a user's session by saving off the token.
export function registerSession(token) {
  window.$sessionStorage.setItem("sessionToken", token);
  _decoded = null;
}

// Clears a user's session, which also removes the token.
export function clearSession() {
  window.$sessionStorage.clear();
  _decoded = null;
}

// Gets a user's session token.
function getSessionToken() {
  return window.$sessionStorage.getItem("sessionToken");
}
