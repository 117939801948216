import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Redirect, Route, Switch, withRouter } from "react-router-dom";

import { BasePureComponent } from "common/components/Base";
import { Breadcrumbs } from "common/components/Breadcrumbs";
import { getSessionId } from "common/util/session";
import { createLoadable } from "common/Loadable";

// load components dynamically
const Home = createLoadable(() => import("app/scenes/Home"));
const SetPassword = createLoadable(() => import("app/scenes/SetPassword"));
const Info = createLoadable(() => import("common/scenes/Info"));
const Guardian = createLoadable(() => import("app/scenes/Guardian"));
const Scholars = createLoadable(() => import("app/scenes/Scholars"));
const ScholarApplication = createLoadable(() =>
  import("app/scenes/ScholarApplication")
);
const EmergencyContacts = createLoadable(() =>
  import("app/scenes/EmergencyContacts")
);
const LegalGuardians = createLoadable(() =>
  import("app/scenes/LegalGuardians")
);
const MakePayment = createLoadable(() => import("app/scenes/MakePayment"));
const PrivacyPolicy = createLoadable(() => import("app/scenes/PrivacyPolicy"));
const TermsOfUse = createLoadable(() => import("app/scenes/TermsOfUse"));

/* The application's main content. This content changes as the user navigates. */
class Main extends BasePureComponent {
  render() {
    // parent
    super.render();

    // for securing some routes behind login
    const loggedIn = getSessionId() && this.props.guardian ? true : false;
    const secureRoute = (component) => {
      return loggedIn ? (
        component
      ) : (
        <Redirect
          to={{
            pathname:
              "/" +
              (this.props.location &&
              this.props.location.pathname &&
              this.props.location.pathname.length > 0
                ? "~" + encodeURIComponent(this.props.location.pathname)
                : ""),
          }}
        />
      );
    };

    return (
      <div className="fsp-main">
        <div className="fsp-page">
          {loggedIn && <Breadcrumbs current={this.props.location.pathname} />}
          <Switch>
            {/* set password */}
            <Route exact path="/password/:token" component={SetPassword} />

            {/* application info */}
            <Route
              exact
              path="/info"
              render={(props) =>
                secureRoute(<Info {...this.props} {...props} />)
              }
            />

            {/* scholar management */}
            <Route
              exact
              path="/scholars/:scholarId/application"
              render={(props) =>
                secureRoute(<ScholarApplication {...this.props} {...props} />)
              }
            />
            <Route
              exact
              path="/scholars"
              render={(props) =>
                secureRoute(<Scholars {...this.props} {...props} />)
              }
            />

            {/* payment */}
            <Route
              exact
              path="/payment"
              render={(props) =>
                secureRoute(<MakePayment {...this.props} {...props} />)
              }
            />

            {/* emergency contacts */}
            <Route
              exact
              path="/account/emergency"
              render={(props) =>
                secureRoute(<EmergencyContacts {...this.props} {...props} />)
              }
            />

            {/* legal guardians */}
            <Route
              exact
              path="/account/guardians"
              render={(props) =>
                secureRoute(<LegalGuardians {...this.props} {...props} />)
              }
            />

            {/* profile management */}
            <Route
              exact
              path="/account"
              render={(props) => (
                // note that this route is not secure on purpose: new account creation
                <Guardian {...this.props} {...props} />
              )}
            />

            {/* privacy policy */}
            <Route
              exact
              path="/privacy"
              render={(props) => <PrivacyPolicy {...this.props} {...props} />}
            />

            {/* terms of use */}
            <Route
              exact
              path="/terms"
              render={(props) => <TermsOfUse {...this.props} {...props} />}
            />

            {/* redirect */}
            <Route
              exact
              path="/~:redirect"
              render={(props) => <Home {...this.props} {...props} />}
            />

            {/* default */}
            <Route render={(props) => <Home {...this.props} {...props} />} />
          </Switch>
        </div>
      </div>
    );
  }
}

// make router props accessible; this is necessary to
// drive re-renders based on path changes
Main = withRouter(connect()(Main));

// set prop types and required-ness
Main.propTypes = { guardian: PropTypes.object };

export default Main;
